<template>
  <div class="app">
    <!-- 网站顶部 -->
    <OfficialOtherHead :homePageSetting="homePageSetting" />
    <!-- search -->
    <div class="main">
      <!-- 搜索 -->
      <div class="searchBox">
        <div class="searchBox-title">搜索资讯/文章</div>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="">
            <el-input v-model="query.search" placeholder="" id="dfi"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 内容 -->
      <div class="panel">
        <div class="container">
          <div class="container-tip" v-if="typeof searchText == 'string'">
            “{{ searchText }}”相关结果：
          </div>
          <ul class="list">
            <li v-for="item in dataList" :key="item.id" class="list-item">
              <a
                :href="getNewsUrl(item)"
                class="list-item-title"
                target="_blank"
              >
                <h4>{{ item.title }}</h4>
                <p>{{ item.publishTimeStr }}</p>
              </a>
            </li>
          </ul>
        </div>
        <!-- 分页 -->
        <div class="paging">
          <div class="paging-first">首页</div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="query.page"
            :page-size="query.pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
          <div class="paging-last">末页</div>
        </div>
      </div>
    </div>
    <!-- end search -->
    <!-- 网站底部 -->
    <OfficialFoot :homePageSetting="homePageSetting" />
  </div>
</template>

<script>
import OfficialOtherHead from "./OfficialOtherHead";
import OfficialFoot from "./OfficialFoot";
import { getHomePageSetting, setSeo } from "../api/defaultDataModel";
export default {
  name: "search",
  components: {
    OfficialOtherHead,
    OfficialFoot,
  },
  data: function () {
    return {
      searchText: undefined,
      //总记录数
      total: 0,
      //分页数据
      dataList: [],
      query: {
        page: 1,
        pageSize: 10,
        search: undefined,
      },
      homePageSetting: getHomePageSetting(),
      dataModel: {
        id: undefined,
        companyName: undefined,
        companyPurpose: undefined,
        imgUrl: undefined,
        smallImgUrl: undefined,
        linkUrl: null,
        companyType: 2,
        briefList: [],
      },
    };
  },
  methods: {
    onSubmit() {
      this.getList();
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
      this.getList();
    },
    getNewsUrl(news) {
      if (news) {
        if (1 === news.articleType) {
          return "/importantDetail?id=" + news.id;
        }
        return "/detail?id=" + news.id;
      }
      return "/";
    },
    getList() {
      this.searchText = undefined;
      //分页获取数据
      this.$http
        .get(this.TzConfig.BASE_URL + "/h5/article/search", {
          params: this.query,
        })
        .then((res) => {
          this.searchText = this.query.search;
          if (8000 === res.data.code) {
            this.total = res.data.data.totalSize;
            this.dataList = res.data.data.list;
          }
        })
        .catch((err) => {
        });
    },
  },

  created() {
    if (typeof this.$route.query.search === "string") {
      this.query.search = this.$route.query.search.trim();
    }
    this.getList();

    //首页数据
    this.$http
      .get(this.TzConfig.BASE_URL + "/h5/homePageSetting/getSetting")
      .then((res) => {
        if (8000 === res.data.code) {
          this.homePageSetting = res.data.data;
          setSeo(
            this.homePageSetting.seoTitle,
            this.homePageSetting.seoDescription,
            this.homePageSetting.seoKeyword
          );
        }
      })
      .catch((err) => {
      });
  },
};
</script>
<style scoped>
.main {
  margin: 88px auto 0;
  width: 100%;
}
.search >>> .el-collapse-item__wrap {
  background-color: #fff;
}
.searchBox {
  margin: 0 auto;
  width: 100%;
  height: 224px;
  background: #f4f6f8;
}
.searchBox .searchBox-title {
  padding: 48px 0 22px;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}
.searchBox >>> .demo-form-inline {
  margin: 0 auto;
  width: 912px;
}
.searchBox >>> .demo-form-inline .el-form-item {
  margin: 0;
  height: 64px;
}
.searchBox >>> .demo-form-inline .el-form-item:first-of-type {
  width: calc(100% - 136px);
}
.searchBox >>> .el-input__inner {
  width: 776px;
  height: 64px;
  font-size: 24px;
  font-weight: 700;
  line-height: 64px;
  color: #1d1d1f;
  border-radius: 4px 0 0 4px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  border-right: 0;
}
.searchBox >>> .el-form-item__content {
  width: 100%;
}
.searchBox >>> .el-button--primary {
  margin: 0 auto;
  width: 136px;
  height: 64px;
  border-radius: 0 4px 4px 0;
  background-color: #0b46b1;
  border: 0;
}
.searchBox >>> .el-button span {
  font-size: 18px;
}
.searchBox .search-close {
  position: absolute;
  top: 15px;
  right: 17px;
  z-index: 2;
  display: block;
  width: 24px;
  height: 24px;
  background: url(../assets/images/search-close.png) no-repeat center/24px;
  cursor: pointer;
}

.panel {
  position: relative;
  z-index: 999;
  margin: 0;
  width: 100%;
}
.container {
  margin: 0 auto;
  padding: 56px 0 0;
  max-width: 1120px;
  background: #fff;
}
.container-tip {
  padding: 0 0 16px;
  font-size: 18px;
  line-height: 36px;
}
.list {
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  width: 100%;
}
.list-item {
  padding: 26px 0;
  width: 100%;
  border-bottom: 1px solid #eee;
}
.list-item:last-of-type {
  margin: 0;
}
.list-item-title {
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}
.list-item-title h4 {
  width: 80%;
  min-height: 64px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*！ autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}
.list-item-title p {
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  width: 20%;
  font-size: 16px;
  line-height: 24px;
  color: rgba(29, 29, 31, 0.5);
  text-align: right;
}
.list-item-title:hover h4,
.module-item-title:hover p {
  color: #0b46b1;
}

.footer {
  margin: 0 auto;
}

@media (max-width: 1400px) {
  .container {
    max-width: auto;
    width: 90%;
  }
}
@media (max-width: 1024px) {
  .searchBox >>> .el-form {
    width: 80%;
  }
  .searchBox >>> .el-form-item {
    position: relative;
  }
  .searchBox >>> .el-form-item:first-of-type {
    width: calc(100% - 136px);
  }
  .searchBox >>> .el-form--inline .el-form-item__content {
    width: 100%;
  }
  .searchBox >>> .el-input__inner {
    width: 100%;
  }
}

@media (max-width: 990px) {
  .main {
    margin: 64px auto 0;
  }
  .hidden-xs {
    display: none;
  }
  .hidden-mx {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .panel {
    padding: 0 0 40px;
  }
  .container {
    padding: 30px 0;
  }
}
@media (max-width: 768px) {
  .list-item-title h4 {
    width: 76%;
  }
  .list-item-title p {
    width: 24%;
  }
}

@media (max-width: 640px) {
  #app,
  .container {
    background-color: fff;
  }
  .main {
    margin: 1.28rem 0 0;
  }
  .searchBox {
    display: none;
  }
  .panel {
    padding: 0;
  }
  .container {
    margin: 0 0.44rem;
    padding: 0.46rem 0;
    width: calc(100% - 0.96rem);
  }
  .list-item {
    padding: 0.32rem 0;
  }
  .list-item:last-of-type {
    border-bottom: 0;
  }
  .list-item-title {
    flex-flow: column wrap;
    justify-content: flex-start;
    width: 100%;
  }
  .list-item-title h4 {
    width: 100%;
    min-height: auto;
    font-size: 0.36rem;
    line-height: 0.56rem;
  }
  .list-item-title p {
    margin: 0.04rem 0 0;
    width: 100%;
    font-size: 0.26rem;
    line-height: 0.36rem;
    text-align: left;
  }
  .paging >>> .el-pagination .btn-next,
  .paging >>> .el-pagination .btn-prev,
  .paging >>> .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    background: transparent;
  }
}
</style>
